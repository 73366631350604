import { useMemo } from "react";
import { useMutation, useQueries, useQuery } from "react-query";
import { isArray } from "lodash";
import {
  getDocumentList,
  getDocumentURL,
  createLoanSubmission,
  getLoanSubmissionStatus,
  getNotification,
  getOpportunity,
} from "../api/scenario";
import { useQueryInvalidationWithNotification } from "./util.hooks";

export const useDocumentList = (scenarioId, queryOptions) => {
  return useQuery(
    ["documentList", scenarioId],
    () => getDocumentList(scenarioId),
    { retry: 3, ...queryOptions }
  );
};
export const useSendNotification = (scenarioId) => {
  return useQuery(
    ["sentDocument", scenarioId],
    () => getNotification(scenarioId),
    { enabled: !!scenarioId }
  );
};
export const useOpportunity = (scenarioId, opportunityId) => {
  return useQuery(
    ["opportunity", scenarioId],
    () => getOpportunity(scenarioId),
    { enabled: !!scenarioId && !!opportunityId }
  );
};
export const useLoanSubmissionStatus = (scenarioId, opportunityId) => {
  return useQuery(
    ["loanSubmissionStatus", scenarioId],
    () => getLoanSubmissionStatus(scenarioId),
    { enabled: !!scenarioId && !!opportunityId }
  );
};

export const useLoanSubmission = (opportunityId) => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation(createLoanSubmission, {
    onSuccess: () => {
      if (!opportunityId) {
        invalidateQueries("scenarioList");
      }
    },
    retry: 2,
  });
};

export const useDocumentURL = (fileList = []) => {
  return useQueries(
    fileList?.map((document) => {
      return {
        queryKey: ["document", document.id],
        queryFn: () => getDocumentURL(document.id, false),
        enabled: !!fileList && fileList?.length > 0,
        select: (data) => {
          return {
            id: document.id,
            url: data,
          };
        },
      };
    })
  );
};

export const useDocumentListWithURL = (scenarioId, opportunityId) => {
  const { data: documentList, isSuccess } = useDocumentList(scenarioId, {
    enabled: !!opportunityId,
  });

  const documentURLListQueries = useDocumentURL(
    isArray(documentList) ? documentList || [] : []
  );
  return useMemo(() => {
    const documentListWithURL = documentURLListQueries
      ?.map((query) => query.data)
      .filter((item) => !!item);
    const hash = new Map();
    if (isSuccess && !!documentListWithURL && documentListWithURL.length > 0) {
      documentList.concat(documentListWithURL).forEach((obj) => {
        hash.set(obj.id, Object.assign(hash.get(obj.id) || {}, obj));
      });
    }
    return Array.from(hash.values());
  }, [documentList, documentURLListQueries, isSuccess]);
};
